import React from "react"
import classnames from "classnames/bind"
import { Row, Col } from "antd"

import { Option } from "./components/Option"
import s from "./index.module.scss"
const cx = classnames.bind(s)

export const OptionGrid = ({
  options,
  onClickOption,
  className
}) => (
  <Row className={cx(className)} gutter={[16, 16]}>
    {options.map(o => {
      const { Logo, title, color } = o
      return (
        <Col xs={8} key={title}>
          <Option
            className={cx('option')}
            onClick={() => onClickOption(o)}
            title={title}
            color={color}
            logo={
              <Logo className={cx('logo')} />
            }
          />
        </Col>
        )
    })}
  </Row>
)