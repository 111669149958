import React from "react"
import classnames from "classnames/bind"
import { ReactComponent as Logo } from "assets/beyond-white.svg"
import { Row, Col } from "antd";
import tmobile from "assets/tmobile.png"
import s from "./index.module.scss";

const cx = classnames.bind(s)

export const Header = () => (
  <header className={cx("header")}>
    <Row
      justify="center"
      className={cx("content")}
    >
      <Col xs={16} >
        <Row>
          <img
            src={tmobile}
            className={cx("tmobile")}
          />
          <div className={cx('plus-container')}>
            <div className={cx("plus")} />
          </div>
          <Logo className={cx("beyond-logo")} />
        </Row>
      </Col>
    </Row>
  </header>
)