import { ReactComponent as Binance } from "assets/binance.svg"
import { ReactComponent as Robinhood } from "assets/robinhood.svg"
import { ReactComponent as Paxos } from "assets/paxos.svg"


export const investList = [
  {
    title: "Binance",
    color: "#f3ba2f",
    Logo: Binance,
    description: "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
  },
  {
    title: "Robinhood",
    color: "#53c38e",
    Logo: Robinhood,
    description: "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
  },
  {
    title: "Paxos",
    color: "#00845E",
    Logo: Paxos,
    description: "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
  }
]