import React from "react"
import { ReactComponent as UberLogo } from "assets/uber.svg"
import { ReactComponent as Gojek } from "assets/gojek.svg"
import { ReactComponent as Grab } from "assets/grab.svg"
import { ReactComponent as Yandex } from "assets/yandex.svg"
import { ReactComponent as Mpesa } from "assets/mpesa.svg"
import { ReactComponent as Alipay } from "assets/alipay.svg"

export const appList = [
  {
    title: "Gojek",
    color: "#00AE00",
    Logo: Gojek,
    url: "https://www.gojek.com/",
    website: "www.gojek.com",
    description: "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
  },
  {
    title: "Uber",
    color: "#000000",
    Logo: UberLogo,
    url: "https://www.uber.com/us/en/ride/how-it-works/uber-cash/",
    website: "www.uber.com",
    description: "Uber, is a multinational company offering services that include peer-to-peer ride sharing, ride service hailing, food delivery, and a microbility system with electric bikes and scooters. The company is based in San Francisco and has operations in over 785 metropolitan areas worldwide. Its platforms can be accessed via its websites and mobile apps."
  },
  {
    title: "Grab",
    color: "#1AAE48",
    Logo: Grab,
    url: "https://www.grab.com/sg/download/",
    website: "www.grab.com",
    description: "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
  },
  {
    title: "Yandex",
    color: "#FF0000",
    Logo: Yandex,
    url: "https://yandex.com/",
    website: "www.yandex.com/",
    description: "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
  },
  {
    title: "Mpesa",
    color: "#83BD42",
    Logo: Mpesa,
    description: "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
  },
  {
    title: "Alipay",
    color: "#00A1E9",
    Logo: Alipay,
    url: "https://intl.alipay.com/",
    website: "intl.alipay.com",
    description: "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
  }
]