import React from "react"
import classnames from "classnames/bind"
import s from "./index.module.scss"
import { Button, Input } from "antd"
const cx = classnames.bind(s)

export const Security = ({
  onValidate
}) => (
  <div className={cx("container")}>
    <h2 className={cx("title")}>
      Beyond Cash Security
    </h2>
    <p className={cx("description")}>
      Please enter the email you sent your cash Beyond to.
    </p>
    <div>
      <span className={cx("input-description")}>Your Email</span>
      <Input size="large" className={cx("input")} />
      <button
        className={cx("submit")}
        onClick={() => onValidate()}>
        Claim Cash
      </button>
    </div>
  </div>
)