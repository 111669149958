import React from "react"
import classnames from "classnames/bind"
import { Modal, Row } from "antd"
import { LinkOutlined, RightOutlined } from "@ant-design/icons"

import s from "./index.module.scss"
const cx = classnames.bind(s)

export const PartnerModal = ({
  className,
  quantity,
  option = {},
  visible,
  ...props
}) => {
  const {
    color,
    Logo,
    title,
    url,
    description,
    website = url
  } = option
  const hasWebsite = url != null
  return (
    <Modal
      className={cx("container", className)}
      {...props}
      footer={[]}
      wrapClassName={cx('modal-wrapper')}
      destroyOnClose={true}
      visible={visible}
    >
      {visible &&
        <>
          <div
            className={cx("option-brand")}
            style={{ backgroundColor: color }}
          >
            <Logo className={cx("option-brand__logo")}/>
          </div>
          <div className={cx("content")}>
            <Row justify="space-between">
              <h3 className={cx("name")}>{title}</h3>
              {hasWebsite &&
                <p>
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener nofollow"
                    className={cx("website")}
                  >
                    <span className={cx("website__url")}>
                      {website}
                    </span>
                    {' '}
                    <LinkOutlined className={cx("website__icon")}/>
                  </a>
                </p>
              }
            </Row>
            {description != null &&
              <div className={cx("description")}>
                <div>
                  <span className={cx("description__title")}>
                    About {title}
                  </span>
                </div>
                <div className={cx("description__content")}>
                  {description}
                </div>
              </div>
            }
            <button
              className={cx("send-button")}
              style={{ backgroundColor: color }}
            >
              Claim {quantity} on {title} {' '}
              <RightOutlined className={cx("send-button__icon")}/>
            </button>
          </div>
        </>
      }

    </Modal>
  )
}