import React, { useState } from "react"
import classnames from "classnames/bind"
import { Row, Select } from "antd"

import s from "./index.module.scss"
const cx = classnames.bind(s)

const { Option } = Select

export const Announcement = ({ className }) => {
  const equivalentValues =  {
    USD: "$10.00",
    EUR: "€8.93",
    INR: "₹744.35"
  }
  const currencies = Object.keys(equivalentValues)

  const [ currency, setCurrency ] = useState(currencies[0])

  return (
    <Row
      className={cx('container', className)}
      justify="space-between"
    >
      <div>
        <h1 className={cx("title")}>
          Hi Sam, you’ve got cash Beyond!
        </h1>
        <p className={cx("description")}>
          Beyond Cash can be invested in crypto or sent to one of our partner platforms
        </p>
      </div>
      <div>
        <div className={cx("amount")}>
          $10.00 TMD
        </div>
        <div className={cx("value-description")}>
          <span className={cx("value")}>
            Valued at {equivalentValues[currency]}
          </span>
          <Select
            className={cx("select-currency")}
            value={currency}
            onChange={setCurrency}
          >
            {currencies.map(c => (
              <Option key={c} value={c}>{c}</Option>
            ))}
          </Select>
        </div>
      </div>
    </Row>
  )
}