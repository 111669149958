import React from "react"
import classnames from "classnames/bind"

import s from "./index.module.scss"
const cx = classnames.bind(s)

export const Option = ({
  className,
  logo,
  onClick,
  color
}) => (
  <button
    onClick={onClick}
    className={cx(className, "container")}
    style={{ backgroundColor: color }}
  >
    {logo}
  </button>
)
