import { ReactComponent as Walmart } from "assets/walmart.svg"
import { ReactComponent as WesternUnion } from "assets/western-union.svg"


export const cashList = [
  {
    title: "Walmart",
    color: "#007dc6",
    Logo: Walmart,
    description: "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
  },
  {
    title: "Western Union",
    color: "#ffde38",
    Logo: WesternUnion,
    description: "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
  }
]