import React from "react"
import classnames from "classnames/bind"
import s from "./index.module.scss"
const cx = classnames.bind(s)

export const Section = ({
  children,
  title,
  className
}) => (
  <div className={cx(className)}>
    <h2 className={cx('title')}>
      {title}
    </h2>

    <div className={cx('display')}>
      {children}
    </div>
  </div>
)