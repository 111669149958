import React, { useState, useCallback, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'antd';
import classnames from "classnames/bind"
import 'antd/dist/antd.css'

import { Header } from "./components/Header"
import { Section } from "./components/Section"
import { OptionGrid } from './components/OptionGrid';
import { appList } from "./config/appList"
import { Announcement } from "./components/Announcement"
import { PartnerModal } from "./components/PartnerModal"
import { investList } from "./config/investList"
import { cashList } from "./config/cashList"
import { cryptoList } from "./config/cryptoList"
import { Security } from "./components/Security"

import s from "./styles/index.module.scss";
const cx = classnames.bind(s)

const App = () => {
  const [ selectedOption, setSelectedOption ] = useState(undefined)
  const [ isPastSecurity, setIsPastSecurity ] = useState(false)

  const hasModal = selectedOption != null && isPastSecurity

  const onSelectNone = useCallback(
    () => setSelectedOption(undefined),
    [setSelectedOption]
  )
  const onSelectOption = useCallback(
    o => setSelectedOption(o),
    [setSelectedOption]
  )
  return (
    <section>
      <Header />
      <Row justify="center">
        {!isPastSecurity ? (
          <Col className="" xs={8}>
            <Security
              onValidate={() => setIsPastSecurity(true)}
            />
          </Col>
        ) : (
          <Col className="" xs={16}>
            <Announcement className={cx("announcement")} />
            <Section
              title="Claim on Apps"
              className={cx('out-section')}
            >
              <OptionGrid
                options={appList}
                onClickOption={onSelectOption}
              />
            </Section>
            <Section
              title="Withdraw as Cash"
              className={cx('out-section')}
            >
              <OptionGrid
                options={cashList}
                onClickOption={onSelectOption}
              />
            </Section>
            <Section
              title="Invest"
              className={cx('out-section')}
            >
              <OptionGrid
                options={investList}
                onClickOption={onSelectOption}
              />
            </Section>
            <Section
              title="Send to Address"
              className={cx('out-section')}
            >
              <OptionGrid
                options={cryptoList}
                onClickOption={onSelectOption}
              />
            </Section>
          </Col>
        )}
      </Row>
      <PartnerModal
        option={selectedOption}
        onCancel={onSelectNone}
        visible={hasModal}
        quantity="$10.00"
      />
    </section>
  )
}

ReactDOM.render(<App />, document.getElementById('root'));
